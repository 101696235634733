import React from 'react';
import ImageNacimiento from '../../../../assets/images/repodni.png';
import './PreviewDNIRepo.css';
import GlobalTitleCertificate from '../../../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import PreviewDNIRepoOptions from './PreviewDNIRepoOptions/PreviewDNIRepoOptions';
import LogotipoRnp from '../../../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';


const PreviewDNIRepo = () => {  

    return (
        <div className='contenedortitle'>
            <div>
                <div>
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate  imgcertificado={ImageNacimiento} nameligth="Reposición de" namebold="" nameorange="DNI" ></GlobalTitleCertificate>
                </div>
                <PreviewDNIRepoOptions></PreviewDNIRepoOptions>
            </div>
        </div>
    );
};

export default PreviewDNIRepo;

// import React from 'react';
// import ImageNacimiento from '../../../../assets/images/repodni.png';
// import './PreviewDNIRepo.css';
// import GlobalTitleCertificate from '../../../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
// import PreviewDNIRepoOptions from './PreviewDNIRepoOptions/PreviewDNIRepoOptions';
// import LogotipoRnp from '../../../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';
// import Close from "../../../../commons/components/Buttons/Close/Close";


// const PreviewDNIRepo = () => {  

//     return (
//         <div className='contenedortitle'>
//             <LogotipoRnp className="logoDNI" />
//             <div className="contentDNI">
//                 <div className="columnDNI">
//                     <GlobalTitleCertificate  imgcertificado={ImageNacimiento} nameligth="Reposición de" namebold="" nameorange="DNI" />
//                 </div>
//                 <div className="columnDNI">
//                     <PreviewDNIRepoOptions />
//                 </div>
//             </div>
//             <div className="alignbtnfooter">
//                 <div>
//                     <h2 className='text-danger'><strong>Tramite Gratuito, NO se imprime comprobante</strong></h2>
//                 </div>
//                  <div className="center">
//                       <Close></Close>
//                  </div>
//                 <div>
//                     <h2>Para su registro le recomendamos sacar una fotografía de esta pantalla</h2>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PreviewDNIRepo;