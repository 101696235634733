import React, { useContext } from 'react';
import { FaHome, FaTimes } from "react-icons/fa";
import './TopBar.css';
import AppContext from "../../../commons/components/AppContext/AppContext";

const TopBar = () => {
    const globalContext = useContext(AppContext);
    const handleHomeClick = () => {
        window.location.href = '/';
    };

    return (
        <div>
            {globalContext.isHome ? (
                <FaHome className="homeIcon" onClick={handleHomeClick} />
            ) : (
                <FaTimes className="exitIcon" onClick={handleHomeClick} />
            )}
        </div>
    );
};

export default TopBar;
