import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../commons/components/AppContext/AppContext";
import "./Authentication.css";
import IdentificationData from "./components/IdentificationData/IdentificationData";
import LogotipoRnp from "../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp";
import { validateWithFacialRecognition, validateWithFingerRecognition, storeBitacora } from "../../Services/services";
import Wainting from "../../commons/components/Waiting/Waiting";
import WarningMessage from "../../commons/components/WarningMessage/WarningMessage";
import imageiconurl from "../../assets/images/iconalert.png";
import Close from "../../commons/components/Buttons/Close/Close";

const Authentication = () => {
    const globalContext = useContext(AppContext);
    const history = useHistory();    
    const [showWaiting, setShowWaiting] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [dni, setDni] = useState(null);
    const [btnEnabled, setBtnEnabled] = useState(false);
    const [shouldValidate, setShouldValidate] = useState(false);
    const [bitacoraSent, setBitacoraSent] = useState(false);
    const [bandera, setbandera] = useState(true);

    useEffect(() => {
        const btn = dni && (globalContext.facial || globalContext.finger);
        setBtnEnabled(btn);
        if (btn && !bitacoraSent) {
            setShouldValidate(true);
        }
    }, [globalContext.facial, globalContext.finger, dni, bitacoraSent]);

    useEffect(() => {
        const validate = async () => {
            if (!shouldValidate || bitacoraSent) return;
            setShowWaiting(true);
            let result = null;
            let bitacora = null;
            if (globalContext.facial) {
                console.log("Validando con facial");
                result = await validateWithFacialRecognition(dni, globalContext.facial);
                if(globalContext.dni === dni) {
                    bitacora = {
                        Qry_ComparaFacialInscrito: {
                            status: result?.status,
                            response: result,
                            timestamp: new Date().toISOString(),
                            dni: dni,
                        }
                    };
                } else {
                    bitacora = {
                        [dni]: [
                            {
                                Qry_ComparaFacialInscrito: {
                                    status: result?.status,
                                    response: result,
                                    timestamp: new Date().toISOString(),
                                    dni: dni,
                                }
                            }
                        ]
                    };
                }
            } else if (globalContext.finger) {
                console.log("Validando con huella");
                result = await validateWithFingerRecognition(dni, globalContext.finger.replace('data:image/png;base64,', ''));                
                if(globalContext.dni === dni) {
                    bitacora = {
                        Qry_ComparaFingerInscrito: {
                            status: result?.status,
                            response: result,
                            timestamp: new Date().toISOString(),
                            dni: dni,
                        }
                    };
                } else {
                    bitacora = {
                        [dni]: [
                            {
                                Qry_ComparaFingerInscrito: {
                                    status: result?.status,
                                    response: result,
                                    timestamp: new Date().toISOString(),
                                    dni: dni,
                                }
                            }
                        ]
                    };
                }
            }
            console.log("Validando------------------------------------------");
            setShowWaiting(false);
            handleValidationResult(result, bitacora);
            setShouldValidate(false);
        };
        validate();
    }, [shouldValidate, bitacoraSent]);

    const handleValidationResult = async (result, bitacora) => {
        if (bitacoraSent) return;
        let response = null;
        if (result?.status) {
            globalContext.setPerson(result.person);
            if(globalContext.dni === dni) {
                response = await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, dni, result.id_customer);
            } else {
                response = await storeBitacora(bitacora, 0, true, dni, result.id_customer);
            }
            globalContext.setIdBitacora(response.data.id);
            globalContext.setIdCustomer(result.id_customer);
            globalContext.setDni(dni);
            setBitacoraSent(true);
            history.push(process.env.REACT_APP_BASE_URL + "documentSelector");
        } else {
            if (result) {
                if(globalContext.dni === dni) {
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, false, dni);
                } else {
                    response = await storeBitacora(bitacora, 0, false, dni, 0);
                    globalContext.setIdBitacora(response.data.id);
                    globalContext.setDni(dni);
                }
                setShowErrorMessage(true);
                globalContext._setFinger(null);
                setBitacoraSent(false); 
            };
        };
        globalContext._setFacial('');
        globalContext._setFinger('');
    };

    const handleClick = () => {
        if (btnEnabled && !bitacoraSent) {
            setShouldValidate(true);
        }
    };

    const onAccept = () => {
        setShowErrorMessage(false);
        if (globalContext.waitFinger && bandera) {
            globalContext.handleClickModalFacial();
            setbandera(false);
        }
        setBitacoraSent(false);
        setShouldValidate(true);
    };

    return (
        <div className="authentication">
            <div>
                <div>
                    <LogotipoRnp></LogotipoRnp>
                    <IdentificationData _onClick={handleClick} _setDni={setDni} />                    
                    <Close />
                    <Wainting show={showWaiting} />
                    <WarningMessage show={showErrorMessage} message={`No se pudo <strong>encontrar la información del ciudadano. <br/><span style="color: #eee823;">Inténtalo otra vez.</span></strong>`} onAccept={onAccept} imageicon={imageiconurl} ayuda={true} />
                </div>
            </div>
        </div>
    );
};

export default Authentication;
