import React from 'react';
import './GeneralPay.css';
import GeneralPayOptions from './components/DocumentsOptions/GeneralPayOptions';
import GlobalTitleCertificate from '../TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import LogotipoRnp from '../Logotipo/LogotipoRnp/LogotipoRnp';
import NameUser from "../UserNames/NameUser/NameUser";
import { Container, Row, Col } from 'react-bootstrap';
import IrMenu from "../Buttons/IrMenu/IrMenu";
import Close from "../Buttons/Close/Close";

const GeneralPay = ({icon,nameligth,namebold,nameorange,description,detail,purchase, onClickFun, onClickpayWithCode ,amount, setShowPlaces}) => {  

    return (
        <div>
            <div className="contenedortitle">
                <LogotipoRnp></LogotipoRnp>
                <NameUser></NameUser>
                <div className='animated animatedFadeInUp fadeInUp messageSelect'>
                    <p style={{ margin: '-40px -30px' }}>Revisa detalladamente los datos de{detail} consultado,<br/> luego procede a <strong>pagar</strong> o <strong>usar TGR</strong><span style={{ color: '#fdc000' }}> si ya haz realizado el pago en un banco.</span></p>
                </div>  
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            <GlobalTitleCertificate   imgcertificado={icon} nameligth={nameligth} namebold={namebold} nameorange={nameorange} ></GlobalTitleCertificate>
                        </Col>
                        <Col>
                            <div>
                                <GeneralPayOptions setShowPlaces={setShowPlaces} nameorange={nameorange} purchase={purchase} description={description} detail={detail} onClickFun={onClickFun} onClickpayWithCode={onClickpayWithCode} amount={amount}>  </GeneralPayOptions>
                            </div> 
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col>
                            <div className="alignbtnfooter">
                                <div className="center">                                
                                    <IrMenu></IrMenu>
                                    <Close></Close>
                                </div>
                            </div>
                        </Col>                 
                    </Row>  
                </Container>
            </div>
        </div>
    );
};

export default GeneralPay;

// import React from 'react';
// import './GeneralPay.css';
// import GeneralPayOptions from './components/DocumentsOptions/GeneralPayOptions';
// import GlobalTitleCertificate from '../TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
// import LogotipoRnp from '../Logotipo/LogotipoRnp/LogotipoRnp';
// import NameUser from "../UserNames/NameUser/NameUser";
// import { Container, Row, Col } from 'react-bootstrap';
// import IrMenu from "../Buttons/IrMenu/IrMenu";
// import Close from "../Buttons/Close/Close";

// const GeneralPay = ({icon,nameligth,namebold,nameorange,description,detail,purchase, onClickFun, onClickpayWithCode ,amount, setShowPlaces}) => {  

//     return (
//         <div>
//             <div className="contenedortitle">
//                 <LogotipoRnp></LogotipoRnp>
//                 <NameUser></NameUser>
//                 {nameorange === 'Documento Nacional de Identificación' ? (
//                     <>
//                     <div className='animated animatedFadeInUp fadeInUp messageSelect'>
//                         <p style={{ margin: '-40px -30px' }}>Revisa detalladamente los datos de{detail} consultada.</p>
//                     </div>  
//                     </>
//                 ) : (
//                     <>
//                         <div className='animated animatedFadeInUp fadeInUp messageSelect'>
//                             <p style={{ margin: '-40px -30px' }}>Revisa detalladamente los datos de{detail} consultado,<br/> luego procede a <strong>pagar</strong> o <strong>usar TGR</strong><span style={{ color: '#fdc000' }}> si ya haz realizado el pago en un banco.</span></p>
//                         </div>                
//                     </>
//                 )}
//                 <Container>
//                     <Row className="align-items-center">
//                         <Col>
//                             <GlobalTitleCertificate   imgcertificado={icon} nameligth={nameligth} namebold={namebold} nameorange={nameorange} ></GlobalTitleCertificate>
//                         </Col>
//                         <Col>
//                             <div>
//                                 <GeneralPayOptions setShowPlaces={setShowPlaces} nameorange={nameorange} purchase={purchase} description={description} detail={detail} onClickFun={onClickFun} onClickpayWithCode={onClickpayWithCode} amount={amount}>  </GeneralPayOptions>
//                             </div> 
//                         </Col>
//                     </Row>
//                     <Row className="align-items-center">
//                         <Col>
//                             <div className="alignbtnfooter">
//                                 <div className="center">                                
//                                     <IrMenu></IrMenu>
//                                     <Close></Close>
//                                 </div>
//                             </div>
//                         </Col>                 
//                     </Row>  
//                 </Container>
//             </div>
//         </div>
//     );
// };

// export default GeneralPay;