import React,{ useEffect, useContext } from 'react';
import './FacialRecognitionButton.css';
import FacialCapture from './componets/FacialCapture/FacialCapture';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';

const FacialRecognitionButton = ({_onClick}) => {    
    const globalContext = useContext(AppContext);

    useEffect(() => {
        const timer = setTimeout(() => {
            globalContext.setwaitFinger(true);
        }, 120000);

        return () => clearTimeout(timer);
    }, []);

    const activeClass = `contenedorbtnautentica ${!globalContext.validateDNI || !globalContext.waitFinger ? 'btnfacialdisable' : ''}`;
    const imageClass = `${!globalContext.waitFinger ? 'btnDisableFaceprint' : 'btnEnableFaceprint'}`;

    const activeButton = () => {
        if (globalContext.validateDNI && globalContext.waitFinger) {
            globalContext.handleClickModalFacial();
        }
    };

    return (
        <>        
            <div className={`FaceContainer animated animatedFadeInUp fadeInUp`}  onClick={activeButton}>
                <div className={activeClass}>
                    <div className={imageClass}></div>
                    <span className="btnselectautentica">Reconocimiento Facial</span>
                </div>                            
            </div>
            <FacialCapture _onClick={_onClick}></FacialCapture>
        </>
    );
};

export default FacialRecognitionButton;
