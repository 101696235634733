import React, {useContext} from 'react';
import './GeneralPayOptions.css';
import { Container, Row, Col } from 'react-bootstrap';
import GeneralPayOptiondBtnPdf from './components/GeneralPayOptiondBtnPdf/GeneralPayOptiondBtnPdf';
import GeneralPayOptionBtnTGR from './components/GeneralPayOptiondBtnTGR/GeneralPayOptionBtnTGR';
import AppContext from "../../../../../commons/components/AppContext/AppContext";

const GeneralPayOptions = ({description,detail,purchase,onClickFun, onClickpayWithCode ,amount, nameorange = 'DEF', setShowPlaces}) => {    
    const globalContext = useContext(AppContext);

    const showPlaces = () => {
        setShowPlaces(true);
    };
    console.log(purchase);

    function formatDni(dni) {
        if (!dni || dni.length !== 13) return dni;
        return `${dni.slice(0, 4)} ${dni.slice(4, 8)} ${dni.slice(8)}`;
    };
    
    return (
        <> 
           <div className="center-screen">
                <Container style={{ width: '380px' }}>
                    <Col className="animated animatedFadeInUp fadeInUp">
                        <div className='messageSelect'>
                            <p style={{ margin: '-15px -15px' }} dangerouslySetInnerHTML={{ __html: description }}/>
                        </div>
                        <div className="contenedorcertificado2">
                            <p>{nameorange === "Defunción" ? purchase?.nameDifunto : nameorange === "Matrimonio" ? purchase?.namepareja : purchase?.name+" "+purchase.lastName}</p>
                        </div>
                        <div className='messageSelect'>
                            <p style={{ margin: '-15px -15px' }}>Con <strong>DNI:</strong></p>
                        </div>
                        <div className="contenedorcertificado2">
                            <p>{formatDni(nameorange === "Defunción" ? purchase?.dniDifunto : (nameorange === "Matrimonio" ? purchase?.dnipareja : purchase?.dniSolicitante))}</p>
                        </div>
                        {nameorange === 'Documento Nacional de Identificación' && 
                            <div>
                                <div className='messageSelect'>
                                    <p style={{ margin: '-15px -15px' }}>Lugar de <strong>Entrega:</strong></p>
                                </div>
                                <div className="contenedorcertificado2">
                                    <p>{ globalContext.codigoEntrega ? globalContext.codigoEntrega : "Sin Seleccionar" }</p>
                                </div>
                            </div>
                        }
                        <div>
                            <p style={{ fontSize: '27px', color: '#837e7e', marginBottom: '25px' }}>Monto a pagar: <span style={{ color: '#F69220', fontSize: '32px', fontWeight: '500' }}>L {amount}.00</span></p>
                        </div>
                        {nameorange === 'Documento Nacional de Identificación' && 
                            <div className='contenedorcertificado5'>
                                <p onClick={showPlaces}>Si quieres modificar tu lugar de entrega por favor dar click aquí</p>
                            </div>
                        }
                        <Row style={{ justifyContent: 'center' }}>
                            <GeneralPayOptiondBtnPdf onClickFun={onClickFun}></GeneralPayOptiondBtnPdf>
                            <div className='animated animatedFadeInUp fadeInUp messageSelect'>
                                <p style={{ margin: '-7px 20px -7px 20px', fontSize: '20px' }}><strong>O</strong></p>
                            </div>                            
                            <GeneralPayOptionBtnTGR onClickpayWithCode={onClickpayWithCode}></GeneralPayOptionBtnTGR>
                        </Row>
                    </Col>
                </Container>
            </div>
        </>
    );
};

export default GeneralPayOptions;

// import React, {useContext} from 'react';
// import './GeneralPayOptions.css';
// import { Container, Row, Col } from 'react-bootstrap';
// import GeneralPayOptiondBtnPdf from './components/GeneralPayOptiondBtnPdf/GeneralPayOptiondBtnPdf';
// import GeneralPayOptionBtnTGR from './components/GeneralPayOptiondBtnTGR/GeneralPayOptionBtnTGR';
// import AppContext from "../../../../../commons/components/AppContext/AppContext";

// const GeneralPayOptions = ({description,detail,purchase,onClickFun, onClickpayWithCode ,amount, nameorange = 'DEF', setShowPlaces}) => {    
//     const globalContext = useContext(AppContext);

//     const showPlaces = () => {
//         setShowPlaces(true);
//     };

//     const handlePay = (event) =>{
//         onClickFun();
//     };

//     console.log(purchase);

//     function formatDni(dni) {
//         if (!dni || dni.length !== 13) return dni;
//         return `${dni.slice(0, 4)} ${dni.slice(4, 8)} ${dni.slice(8)}`;
//     };
    
//     return (
//         <> 
//            <div className="center-screen">
//                 <Container style={{ width: '380px' }}>
//                     <Col className="animated animatedFadeInUp fadeInUp">
//                         <div className='messageSelect'>
//                             <p style={{ margin: '-15px -15px' }} dangerouslySetInnerHTML={{ __html: description }}/>
//                         </div>
//                         <div className="contenedorcertificado2">
//                             <p>{nameorange === "Defunción" ? purchase?.nameDifunto : nameorange === "Matrimonio" ? purchase?.namepareja : purchase?.name+" "+purchase.lastName}</p>
//                         </div>
//                         <div className='messageSelect'>
//                             <p style={{ margin: '-15px -15px' }}>Con <strong>DNI:</strong></p>
//                         </div>
//                         <div className="contenedorcertificado2">
//                             <p>{formatDni(nameorange === "Defunción" ? purchase?.dniDifunto : (nameorange === "Matrimonio" ? purchase?.dnipareja : purchase?.dniSolicitante))}</p>
//                         </div>
//                         {nameorange === 'Documento Nacional de Identificación' ? (
//                             <>
//                                 <div>
//                                     <div className='messageSelect'>
//                                         <p style={{ margin: '-15px -15px' }}>Lugar de <strong>Entrega:</strong></p>
//                                     </div>
//                                     <div className="contenedorcertificado2">
//                                         <p>{ globalContext.codigoEntrega ? globalContext.codigoEntrega : "Sin Seleccionar" }</p>
//                                     </div>
//                                     <br/>
//                                 </div>
//                                 <div className='contenedorcertificado5'>
//                                     <p onClick={showPlaces}>Si quieres modificar tu lugar de entrega por favor dar click aquí</p>
//                                 </div>
//                                 <br/>
//                                 <br/>
//                                 <Row style={{ justifyContent: 'center' }}>
//                                     <div className='contenedorcertificado3 animated animatedFadeInUp fadeInUp' style={{ background: '#e8eaee' }}>
//                                         <a style={{ color: '#0295d5' }} onClick={handlePay}>
//                                             <p><img className="icondescarga" src={require('../../../../../assets/images/repodni.png')} alt="Impresion" /> Solicitar</p>                          
//                                         </a>
//                                     </div>
//                                 </Row>
//                             </>
//                         ) : (
//                             <>
//                                 <div>
//                                     <p style={{ fontSize: '27px', color: '#837e7e', marginBottom: '25px' }}>Monto a pagar: <span style={{ color: '#F69220', fontSize: '32px', fontWeight: '500' }}>L {amount}.00</span></p>
//                                 </div>
//                                 <Row style={{ justifyContent: 'center' }}>
//                                     <GeneralPayOptiondBtnPdf onClickFun={onClickFun}></GeneralPayOptiondBtnPdf>
//                                     <div className='animated animatedFadeInUp fadeInUp messageSelect'>
//                                         <p style={{ margin: '-7px 20px -7px 20px', fontSize: '20px' }}><strong>O</strong></p>
//                                     </div>                            
//                                     <GeneralPayOptionBtnTGR onClickpayWithCode={onClickpayWithCode}></GeneralPayOptionBtnTGR>
//                                 </Row>
//                             </>
//                         )}
//                     </Col>
//                 </Container>
//             </div>
//         </>
//     );
// };

// export default GeneralPayOptions;