import axios from "axios";
import {invokeValidateTGR,invokePayTGR,invokeGetPayDocument} from "./InvokeServices"

const JSEncrypt = require("jsencrypt/bin/jsencrypt.min.js");
console.log(JSEncrypt);
const encrypt = new JSEncrypt();
const aesjs = require("aes-js");
const IS_DEBUG = process.env.REACT_APP_IS_DEBUG;
var cclog = 0;

export function activatePinPad(json) {
     const baseUrl = process.env.REACT_APP_EMU_URL;
     console.log("baseUrl", baseUrl);
     return axios
          .post(`${baseUrl}/socketBridge`, json, {
               headers: {
                    "Content-Type": "application/json",
               },
          })
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return { status: false };
          }
     );
};

export function sentToEM(json) {
     const baseUrl = process.env.REACT_APP_EMU_URL;
     console.log("baseUrl", baseUrl);
     return axios
          .post(`${baseUrl}/socketBridge`, json, {
               headers: {
                    "Content-Type": "application/json",
               },
          })
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return { status: false };
          }
     );
};

export function sentToHuella() {
     const baseUrl = process.env.REACT_APP_EMU_URL;
     console.log("baseUrl", baseUrl);
     return axios
          .post(`${baseUrl}/captureFingerprint`, {
               headers: {
                    "Content-Type": "application/json",
               },
          })
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return { status: false };
          }
     );
};

export function sendToPrinter(certificate, type = 3, statusImp = false) {
     if(statusImp == false){
          return;
     };
     console.log('entra a esta function');
     console.log(certificate);
     console.log('imprimiendo');
     const baseUrl = process.env.REACT_APP_EMU_URL;
     console.log("baseUrl", baseUrl);
     return axios
          .post(
               `${baseUrl}/instantPrint`,
               {
                    idPrinter: type,
                    pdfBase64: certificate,
               },
               {
                    headers: {
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return { status: false };
          }
     );
};

export function activatePoint(pointCode, password) {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     return axios
          .post(`${baseUrl}/authorizeRNPPoint`, {
               pointCode: pointCode,
               password: password,
          })
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return { status: false };
          }
     );
};

export function getDataPinPad() {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     const storedToken = localStorage.getItem("tk");
     return axios
          .get(`${baseUrl}/getDataPinPad `, {
               headers: {
                    Authorization: `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
               },
          })
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export function savePinPad(body) {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(`${baseUrl}/savePinpad `, body, {
               headers: {
                    Authorization: `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
               },
          })
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export async function updateAsDelivered(lastID, obj_validar = '') {
     console.log("======================== updateAsDelivered  ↓ "); 
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     console.log("lastID", lastID);
     const storedToken = localStorage.getItem("tk"); 
     const requestOptions = {
          headers: {
               'Authorization': `Bearer ${storedToken}`,
               'Content-Type': 'application/json'
          }
     }; 
     try {
          const response = await axios.post(
                    `${baseUrl}/updateasdelivery`,
                    { lastID: lastID, obj_validar: obj_validar },
                    requestOptions
          ); 
          console.log("Respuesta del servicio:", response);
          console.log("======================== updateAsDelivered ↑");
          if (response.status !== 200) {
               throw new Error('Error en la solicitud');
          };
          return response.data;
     } catch (error) {
          console.log("Error al consumir el servicio:", error);
          console.log("======================== updateAsDelivered  ↑ ");
          throw error;
     };
};
 
export function isDNIAllowedForFlow(dni){
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     console.log("dni", dni);
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(
               `${baseUrl}/validatednirepo`,
               {
                    dniSolicitante: dni,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export function getDeathCertificate(dni) {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     console.log("dni", dni);
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(
               `${baseUrl}/getdeathlist`,
               {
                    dni: dni,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export const checkout = async (obj, resultCreateTGR, id_bitacora=0) => {
     try {
          console.log("Checkout obj", obj);
          console.log("Checkout resultCreateTGR", resultCreateTGR);
          let resultGetPayDocument;
          const withRetry = async (func, args, data_bitacora, maxRetries = 1) => {
               let retryCount = 0;
               while (retryCount < maxRetries) {
                    try {
                         return await func(...args, data_bitacora);
                    } catch (error) {
                         console.error(`Error en ${func.name}: ${error.message}`);
                         retryCount++;
                         if (retryCount >= maxRetries) {
                              throw new Error(`Se alcanzó el máximo de intentos (${retryCount}) para ${func.name}.`);
                         };
                    };
               };
          };
          if (resultCreateTGR?.status === true) {
               console.log("Creado", resultCreateTGR);
               const tgrObj = resultCreateTGR.result;
               if (tgrObj.estado === "CREADO") {
                    const validateTGRArgs = [{
                         tgr: tgrObj.tgr,
                    }];
                    const data_bitacora = { 
                         dni : obj.compra.dniSolicitante, 
                         id_bitacora 
                    };
                    const resultValidateTGR = await withRetry(invokeValidateTGR, validateTGRArgs, data_bitacora);     
                    if (resultValidateTGR?.status === true || resultValidateTGR?.result?.Header?.ResponseHeader?.messages.includes("PAGADO")) {
                         console.log("OBJ CHCk", obj);
                         const payTGRArgs = [{
                              tgr: tgrObj.tgr,
                              estado: tgrObj.estado,
                              nombreEnRecibo: obj.compra.name + " " + obj.compra.lastName,
                              monto: tgrObj.monto,
                              authNum: obj.responseVpos.authNum,
                         }];
                         const resultPayTGR = await withRetry(invokePayTGR, payTGRArgs , data_bitacora);     
                         if (resultPayTGR?.status === true || resultPayTGR.result.Header.ResponseHeader.messages == 'El recibo ya está en estado PAGADO.') {
                              const getPayDocumentArgs = [{
                              tgr: tgrObj.tgr,
                              compra: obj.compra,
                              responseVpos: obj.responseVpos,
                              CodigoEntrega: obj.CodigoEntrega
                              }];
                              resultGetPayDocument = await withRetry(invokeGetPayDocument, getPayDocumentArgs, data_bitacora);
                         };
                    };
               };
          }; 
          return resultGetPayDocument;
     } catch (error) {
          console.error(`Error en checkout: ${error.message}`);
          const bitacoraCheckoutError = {
               checkoutError: {
                    status: false,
                    data: error,
                    timestamp: new Date().toISOString(),
                    dni: obj.compra.dniSolicitante,
               }
          };
          await storeBitacora(JSON.stringify(bitacoraCheckoutError), id_bitacora, true, obj.compra.dniSolicitante);
          return false;
     };
};

export const validateStatusTGR = async (codigo) => {
     const baseUrl = process.env.REACT_APP_API_URL;
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(
               `${baseUrl}/validatestatustgr`,
               {
                    tgr: codigo,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export const checkoutCode = async (obj, tgr, datos_bitacora) => {
     if (tgr) {
          try {
               const body = {
                    tgr: tgr,
                    compra: obj.compra,
                    responseVpos:obj.responseVpos,
                    CodigoEntrega: obj.CodigoEntrega ?? ''
               };
               const resultGetPayDocument = await invokeGetPayDocument(body, datos_bitacora);
               return resultGetPayDocument;
          } catch (error) {
               const bitacora = {
                    getPayDocument: {
                        status: false,
                        data: error,
                        timestamp: new Date().toISOString(),
                        dni: datos_bitacora.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), datos_bitacora.id_bitacora, true, datos_bitacora.dni);
          };
     };
};

export const validateCodeTGR = async (tgr) => {
     const baseUrl = process.env.REACT_APP_API_URL;
     const storedToken = localStorage.getItem("tk");
     console.log('enviando tgr', tgr)
     return axios
          .post(
               `${baseUrl}/validateCodeRNP`,
               {
                    tgr: tgr,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export const pdfToImage = async (pdfData) => {
     const baseUrl = process.env.REACT_APP_API_URL_PDF;
     console.log("pdf_enviando", pdfData);
     return axios
          .post(
               `${baseUrl}/procesar-pdf`,
               {
                    pdf_b64: pdfData,
               },
          )
          .then((response) => {
               console.log("respuesta imagen to pdf", response);
               console.log("Respuesta del servicio pdf to image:", response.data);
               return response.data.imagen_base64;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return { status: false };
          }
     );
};

export function getListPlacesPickup() {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     const storedToken = localStorage.getItem("tk");
     console.log("token", storedToken);   
     return axios
          .get(
               `${baseUrl}/getListPlacesPickup`,
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data.body);
               return response.data.body;
          })        
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};   

export function getListMarriage(dni) {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     console.log("dni", dni);
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(
               `${baseUrl}/getlistmarriage`,
               {
                    dni: dni,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export function getDecency(dni) {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     console.log("dni", dni);
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(
               `${baseUrl}/getdecency`,
               {
                    dni: dni,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export function getCertificateBirth(dni) {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     console.log("dni", dni);
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(
               `${baseUrl}/certificatebirth`,
               {
                    dni: dni,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               throw error;
          }
     );
};

export function validateWithFingerRecognition(dni, imgBase64) {
     const baseUrl = process.env.REACT_APP_API_URL;
     console.log("baseUrl", baseUrl);
     console.log("dni", dni);
     console.log("imgBase64 Slice 50", imgBase64.slice(0, 50));
     const storedToken = localStorage.getItem("tk");
     return axios
          .post(
               `${baseUrl}/fingerprint`,
               {
                    dni: dni,
                    base64Img: imgBase64,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return null;
          }
     );
};

export function validateWithFacialRecognition(dni, imgBase64) {
     const baseUrl = process.env.REACT_APP_API_URL;
     const storedToken = localStorage.getItem("tk");
     console.log("baseUrl", baseUrl);
     console.log("dni", dni);
     console.log("imgBase64 Slice 50", imgBase64.slice(0, 50));
     console.log("token", storedToken);
     return axios
          .post(
               `${baseUrl}/facerecognition`,
               {
                    dni: dni,
                    base64Img: imgBase64,
               },
               {
                    headers: {
                         Authorization: `Bearer ${storedToken}`,
                         "Content-Type": "application/json",
                    },
               }
          )
          .then((response) => {
               console.log("Respuesta del servicio:", response.data);
               return response.data;
          })
          .catch((error) => {
               console.log("Error al consumir el servicio:", error);
               return null;
          }
     );
};

/**
 * Function to print a basic log or error if Debug env.IS_Debug
 *
 * @param {*} obj to print
 * @param {INT} 'type of log
 */
export function clog(obj, type = 0) {
     if (IS_DEBUG === "true") {
          switch (type) {
               case 0:
                    console.log("RT(" + cclog++ + ")", obj);
                    break;
               case 1:
                    console.warn("RT(" + cclog++ + ")", obj);
                    break;
               default:
                    console.error("RT(" + cclog++ + ")", obj);
                    break;
          };
     };
};

/**
 * Function to codify ld an lk for gp
 * @param {JSON} obj card data
 * @param {*} pair_
 * @return {JSON} ld and lk
 */
function pack(obj, pair_) {
     var pair = pair_ !== undefined ? pair_ : generateAESPairs();
     var textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj));
     var aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s));
     var encryptedBytes = aesCtr.encrypt(textBytes);
     var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
     var returnObj = {
          ld: encryptedHex,
          lk: encrypt.encrypt(JSON.stringify(pair)),
     };
     return returnObj;
};

/**
 * Function to return AESPairs
 */
function generateAESPairs() {
     var key = [];
     var iv = 0;
     for (var g = 0; g < 16; g++) {
          key.push(Math.floor(Math.random() * 255));
     };
     for (var k = 0; k < 16; k++) {
          iv = Math.floor(Math.random() * 255);
     };
     return {
          k: key,
          s: iv,
     };
};

/**
 * Function to get type of card
 *
 * @param {STRING} ccx card number
 * @return {STRING} type of card
 */
export function creditCardType(ccx) {
     let cc = ccx.replace(/ /g, "").replace(/_/g, "");
     let amex = new RegExp("^3[47][0-9]{13}$");
     let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
     let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
     let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");
     let mastercard = new RegExp("^5[1-5][0-9]{14}$");
     let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");
     let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
     let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
     let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");
     let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
     let jcb = new RegExp("^35[0-9]{14}[0-9]*$");
     if (visa.test(cc)) {
          return "VISA";
     };
     if (amex.test(cc)) {
          return "AMEX";
     };
     if (mastercard.test(cc) || mastercard2.test(cc)) {
          return "MASTERCARD";
     };
     if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
          return "DISCOVER";
     };
     if (diners.test(cc)) {
          return "DINERS";
     };
     if (jcb.test(cc)) {
          return "JCB";
     };
     if (cup1.test(cc) || cup2.test(cc)) {
          return "CHINA_UNION_PAY";
     };
     return "undefined";
};

export const storeBitacora = async (bitacora, id_bitacora = 0, login = false, dni , id_customer) => {
     try {
          const baseUrl = process.env.REACT_APP_API_URL;
          const token = localStorage.getItem("tk");
          const data = {
               data : bitacora, 
               id_bitacora, 
               login, 
               dni, 
               id_customer
          };
          const response = await axios.post(
               `${baseUrl}/bitacora`,
               data,
               {
                    headers: {
                         'Authorization': `Bearer ${token}`
                    }
               }
          );
          console.log('Response:', response.data);
          return response.data;
     } catch (error) {
          console.error('Error storing bitacora:', error);
     };
};