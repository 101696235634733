import React, { useEffect, useContext, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './DniInput.css';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';

const DniInput = ({ _setDni, _setIsKeyboardVisible }) => {
    const globalContext = useContext(AppContext);
    const [inputValue, setInputValue] = useState('');
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const [firstTimeKeyBoard, setFirstTimeKeyBoard] = useState(true);
    const [showErrorMessageDNI, setShowErrorMessageDNI] = useState(false);

    useEffect(() => {
        if (inputValue.length === 15) {
            disableKeyBoard();
        }
        if (_setDni) {
            _setDni(inputValue.replace(/\s/g, ''));
        }
    }, [inputValue]);
    
    useEffect(() => {
        _setIsKeyboardVisible(isKeyboardVisible);
    }, [isKeyboardVisible, _setIsKeyboardVisible]);

    const enableKeyBoard = () => {
        setFirstTimeKeyBoard(false);
        setIsKeyboardVisible(true);
    };

    const disableKeyBoard = () => {
        if (isKeyboardVisible) { 
            setIsKeyboardVisible(false);
        }
    };

    const formatDNI = (value) => {
        value = value.replace(/\D/g, '');
        if (value.length > 4) {
            value = value.slice(0, 4) + ' ' + value.slice(4);
        };
        if (value.length > 9) {
            value = value.slice(0, 9) + ' ' + value.slice(9);
        };
        return value;
    };

    const onKeyPressKeyBoard = (button ) => {
        if (button === "{bksp}") {
            console.log("Button pressed", button);
            setInputValue(prev => formatDNI(prev.slice(0, -1)));
            globalContext.setValidateDNI(false);
        } else if (button === "{enter}") {
            console.log("Button pressed", button);
            disableKeyBoard();
            if (!globalContext.validateDNI) {
                setShowErrorMessageDNI(true);
            };
        } else {
            if (inputValue.length <= 14) {
                console.log("Button pressed", button);
                const formattedInput = formatDNI(inputValue + button);
                setInputValue(formattedInput);
                globalContext.setValidateDNI(false);
                console.log("Input changed", formattedInput);
                if (inputValue.length === 14) {
                    globalContext.setValidateDNI(true);
                    setShowErrorMessageDNI(false);
                    console.log("final", formattedInput);
                    globalContext.handleClickFinger();
                };
            }
        };
    };

    const onChangeInput = (e) => {
        let formattedValue = formatDNI(e.target.value);
        setInputValue(formattedValue);
        _setDni(formattedValue.replace(/\s/g, '')); // Eliminar espacios antes de enviar el valor
    };

    return (
        <>
            <div>
                <div className='animated animatedFadeInUp fadeInUp'>
                    <input value={inputValue} className='inputDNI' type="text" maxLength={15} pattern="[A-Za-z0-9]*" onFocus={enableKeyBoard} onChange={onChangeInput} placeholder='DNI' readOnly={true} />
                </div>
                {showErrorMessageDNI && (
                    <div>
                        <span style={{ color: 'red' }}>El Documento Nacional de Identificación debe tener 13 dígitos</span>
                    </div>
                )}
                {isKeyboardVisible && (
                    <div
                        className="keyboard-container"
                        style={{
                            animation: `${!firstTimeKeyBoard ? (isKeyboardVisible ? "slideIn" : "slideOut") : ""} 0.5s forwards`
                        }}
                    >
                        <Keyboard
                            theme={"hg-theme-default myTheme1"}
                            layoutName={"caps"}
                            onKeyPress={onKeyPressKeyBoard}
                            layout={{
                                caps: [
                                    "1 2 3",
                                    "4 5 6",
                                    "7 8 9",
                                    "{bksp} 0 {enter}"
                                ]
                            }}
                            display={{
                                '{bksp}': '<',
                                '{enter}': 'Salir'
                            }}
                            buttonTheme={[
                                {
                                    class: "hg-enter",
                                    buttons: "{enter}"
                                },
                                {
                                    class: "hg-button-bksp",
                                    buttons: "{bksp}"
                                }
                            ]}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default DniInput;